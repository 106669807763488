import React from 'react'



class Whatis extends React.Component {

  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg bgremove'>
      <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
              White Label UniSwap Clone Script?</h2>
            </div>
          </div>

          <div className="text-center">
            <p className="pharagraph" >Uniswap Clone Script is the pre-fabricated DeFi exchange clone software that holds all the current functionalities and plug-ins of the existing platform - UniSwap. Our premium Uniswap clone script is ideal for creating a high-performance DeFi-based DEX platform that functions 100% similar to UniSwap. Just like the UniSwap, our uniswap clone script is also based on the powerful Ethereum blockchain architecture. 
            </p>
            <p className='pharagraph'>
            Our Uniswap clone script comes with the latest AMM protocol, so your users can facilitate automated liquidity provision on Ethereum. The entire process can be automated so there is no need to get third parties or middlemen involved. Our script is open for customization, so you can customize the platform as per your taste without any hassle. Besides, this script is equipped with the ideal features and it will be perfectly adaptable to the DeFi ecosystem.  
            </p>
            <p className="pharagraph mb-0">The UniSwap clone is specially designed for people who are looking for a modern solution to initiate a function-rich DeFi exchange on the Ethereum blockchain. The error-free Uniswap clone software that you get from us can solve all the liquidity problems which are existing currently in the market. This script has an astounding UI/UX, so it helps to enhance user engagement and offers an outstanding DeFi environment.
            </p>
          </div>
        </div>
      </div>
      </div>
    </section>
    )
  }
}

export default Whatis