import React from 'react'

import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our UniSwap Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">AMM - Automated Market Maker</h4>
                <p className="pharagraph">Our Uniswap clone script comes with a tool for providing liquidity to the users. This can automate the trade through liquidity pools, thus eliminating the regular order book system. 
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/amm.png"
                      alt="AMM - Automated Market Maker"
                      width={550}
                    />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/exchange-instantly.png"
                      alt="Exchange Instantly"
                      width={600}
                    />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Exchange Instantly</h4>
                <p className="pharagraph">We come up with superlative external wallets such as Trust Wallet, Binance Wallet, Coinbase Wallet, Metamask Wallet, etc. Traders can easily exchange Ethereum-based tokens immediately. The software is energized by smart contracts to support the automated transactions happening in a high-speed manner.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Transaction Speed</h4>
                <p className="pharagraph">Our clone script permits thousands of transactions per second, allowing numerous users to utilize the exchange concurrently, without any delay.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/transaction-speed.png"
                      alt="Transaction Speed"
                      width={580}
                    />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/security.png"
                      alt="Security"
                      width={600}
                    />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Security</h4>
                <p className="pharagraph">We supply untrackable multilayered security mechanisms that are the official mark of Blockchain technology. Multiple security protocols make it practically impossible to compromise privacy and financial transactions.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>

          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Oracles</h4>
                <p className="pharagraph">We come up with a highly secure exchange that saves data on the outside and enables the user to view changeless pricing information.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/oracles.png"
                      alt="Oracles"
                      width={530}
                    />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures