import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of</span>
            Our UniSwap Clone Script </h3>
            <p className="pharagraph head">We at Coinsclone undergo the following procedures for Uniswap clone script development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/binance/planning-and-requirment-gathering.png" alt="Development approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirement Gathering</h5>
            <p className="pharagraph" >We consider every customer unique and consequently, a thorough analysis of your requirements will be done based on your unique requirements and expectations.
            </p>
            <br />
            <h5 >Planning </h5>
            <p className="pharagraph" >Our business experts will plan and provide the finest solution depending on the client’s business concept.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p className="pharagraph" >Ultimately it depends on the sort of experience your users have while interacting with your decentralized platform. We will leave no stone unturned to make this the best experience for your users.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">Although the Uniswap clone script provides you with the complete framework, it will need to be customized to turn it into your business identity. You may also need to add new features or modify existing features. We can help you with that.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/binance/design-development.png" alt="Designing" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/binance/testing-and-deployment.png" alt="Testing and Deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Testing</h5>
            <p  className="pharagraph">This is just to make sure that everything works the way it should and there are no kinks.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph mb-0">Maintenance is optional, but after completing the solution, we will deploy it on your server. Additionally, if you also build your mobile app through us, we will deploy the mobile app on the appropriate servers.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach