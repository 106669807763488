import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
              Our UniSwap Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Farm
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Multiple Wallet support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Transaction history
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Connect Wallet
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Own custodian
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Pools 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Custom Pools
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  DeFi Staking
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Charts
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Assembled in Solidity
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Farm
                        </h4>
                        <p className="pharagraph">Our Uniswap clone script enables users to earn rewards for staking the LP tokens in the farms that are powered by smart contracts.
                        </p>
                        <div className="text-left mt-4">
                          <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/farm.png"
                      alt="Farm"
                      width={340}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multiple wallet support
                        </h4>
                        <p className="pharagraph">Our Uniswap clone script is incorporated with all popular and custom wallets for customers to complete their trades in a hassle-free manner.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap/multiple-wallet-support.png"
                      alt="Multiple wallet support"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Transaction history

                        </h4>
                        <p className="pharagraph">Our ultra-modern Binance clone script software is empowered with the latest and transparent live order book system. This provides the list of all open orders that are available on your exchange for a particular crypto trading pair.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/transaction-history.png"
                      alt="Transaction history"
                      width={430}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Connect Wallet
                        </h4>
                        <p className="pharagraph">Our Uniswap script allows users to connect several wallets including Metamask and Walletconnect. Once connected users can swap their Ethereum-based tokens easily and securely.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap/connect-wallet.png"
                      alt="Connect Wallet"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Own custodian
                        </h4>
                        <p className="pharagraph">Our software will safely keep their private keys which implies they can be the custodian of their own assets. This lets users exchange those with high liquidity pools.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap/own-custodian.png"
                      alt="Own custodian"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Pools
                        </h4>
                        <p className="pharagraph">Users can stake their preferred tokens in the respective token pools for a specific time to earn high income.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/pools.png"
                      alt="Pools"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Custom Pools
                        </h4>
                        <p className="pharagraph">Using our Uniswap clone script, create your own liquidity pool so that the other users who were termed as liquidity providers can add tokens to your pool and earn LP tokens.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap/custom-pools.png"
                      alt="Custom Pools"
                      width={315}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">DeFi Staking
                        </h4>
                        <p className="pharagraph">Our platform supports the locking of tokens into the inbuilt DeFi smart contracts that let users earn additional tokens as rewards.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/defi-staking.png"
                      alt="DeFiStaking"
                      width={470}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Charts
                        </h4>
                        <p className="pharagraph">Check top pools, trading volume, token prices, and overall transactions in a personalized overview space. These analytics may help users improve their platform usability.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/charts.png"
                      alt="Charts"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Assembled in Solidity
                        </h4>
                        <p className="pharagraph">Our Uniswap clone script is readily developed in the frameworks of Solidity which is one of the most standard solutions to foster valid and verified transactions.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/assembled-in-solidity.png"
                      alt="Assembled in Solidity"
                      width={310}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures