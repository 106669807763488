import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span> Coinsclone For UniSwap Clone Script?</h3>
                <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img  width="522px" height="323px" src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/why-choose-coinsclone-for-uni-swap-clone-script.png" alt="Why Choose our Uniswap Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is a renowned DeFi exchange clone script provider in the blockchain industry. We have rock-solid experience in crafting tailor-made clone scripts based on clientele needs. Our premium Uniswap clone script is a ready-made solution that will allow you to deploy a fully functional DeFi-based decentralized exchange protocol on the Ethereum blockchain network instantly. By using our script, you can establish your own DeFi exchange similar to UniSwap at a moderate cost.
              </p>
              <p className="pharagraph mb-0">We have a qualified and well-skilled team of blockchain developers to develop & deploy a feature-rich DeFi exchange like Uniswap. Our software and website development team will follow only the latest technology stacks. Therefore, the outcome of the product would be a phenomenal one. When you purchase our Uniswap clone script, you have access to our blockchain experts. We guarantee on-time delivery and top-notch quality with 100% customization and branding. Before deployment, our team will go through strenuous testing & after deployment, we will provide you with ongoing support.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose