import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Uniswap/Banner"
import Whatis from "../components/Uniswap/Whatis"
//import QuickviewSection from "../components/Uniswap/Quickview"
import HowOur from "../components/Uniswap/HowOur"
import TopFeatures from "../components/Uniswap/TopFeatures"
import CoreFeatures from "../components/Uniswap/CoreFeatures"
import SecurityofOur from "../components/Uniswap/SecurityofOur"
import TechStack from "../components/Uniswap/TechStack"
import FaqSection from "../components/Uniswap/FaqSection"
import WhyShould from "../components/Uniswap/WhyShould"
import DevApproach from "../components/Uniswap/DevApproach"
import WhyChoose from "../components/Uniswap/WhyChoose"
import Addon from "../components/Uniswap/Addon"
import Testimonial from "../components/Uniswap/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"




const Clone = () => (


  <section className="landing clone">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Uniswap Clone Script| Uniswap clone software</title>
        <meta name="description" content="Uniswap Clone Script is a ready-made decentralized exchange software solution that replicates the features and functionalities of Uniswap and it’s built on the Ethereum blockchain." />
        <meta property="keywords" content="White Label Uniswap Clone Script" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Uniswap Clone Script| Uniswap clone software" />
        <meta property="og:description" content="Uniswap Clone Script is a ready-made decentralized exchange software solution that replicates the features and functionalities of Uniswap and it’s built on the Ethereum blockchain." />
        <meta property="og:url" content="https://www.coinsclone.com/uniswap-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/uniswap-clone-script.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Uniswap Clone Script is a ready-made decentralized exchange software solution that replicates the features and functionalities of Uniswap and it’s built on the Ethereum blockchain." />
        <meta name="twitter:title" content="Uniswap Clone Script| Uniswap clone software" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/uniswap-clone-script.png" />
        <link rel="canonical" href="https://www.coinsclone.com/uniswap-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | UniSwap Clone Script</span>
        </div>
      </div>
      <Whatis />
      {/* <QuickviewSection /> */}
      <HowOur />
      <WhyShould />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <WhyChoose />
      <DevApproach />
      <Testimonial />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Clone
