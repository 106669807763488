import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Why should</span> you build a</span>
              DeFi Exchange using Uniswap Clone Software?
              </h2>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/why-should-uniswap-clone-software.png"
                      alt="DeFi Exchange using Uniswap Clone Software"
                      width={600}
                    />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >Built upon the most trusted Ethereum blockchain network.
                </li>
                <li className="pharagraph" >The premium Uniswap clone software has lots and lots of customizable options.
                </li>
                <li className="pharagraph" >Allows your users to swap ERC20 tokens instantly and securely.
                </li>
                <li className="pharagraph" >The Uniswap DEX clone script is built with upgraded DeFi Exchange protocol features and high-end security modules.
                </li>
                <li className="pharagraph" >By using this White-label Uniswap exchange clone software, you can launch a DeFi exchange in the minimum possible time.
                </li>
                <li className="pharagraph" >High chances of success in a short period.</li>
                <li className="pharagraph" >Generate high ROI with less effort.
                </li>
                <li className="pharagraph" >It is a cost-effective solution, so it reduces your investment in Uniswap exchange clone development. 
                </li>
                <li className="pharagraph" >The Uniswap clone is 100% tested and glitch-free.
                </li>
                <li className="pharagraph" >Comes with multiple crypto wallet connection support.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould