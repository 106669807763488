import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
              UniSwap Clone Script Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Multilingual support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Purely Decentralized
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  Bug bounty reward system
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Free Listing of Tokens
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Dark/Light Mode
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Multilingual support
                      </h4>
                      <p className="pharagraph">We provide multiple language support to make your Uniswap clone platform take part and engage with global crypto users & investors.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent />
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="320px" height="320px" src="https://coinsclone.mo.cloudinary.net/images/uniswap/multilingual-support.png" alt="Multilingual support" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Purely Decentralized
                      </h4>
                      <p className="pharagraph">Elimination of the traditional trade system like register, login & document verification makes our Uniswap clone script stay anonymous. This saves time by allowing you to just connect the wallets and start swapping the tokens.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="320px" height="320px" src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/purely-decentralized.png" alt="Purely Decentralized" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Free Listing of Tokens
                      </h4>
                      <p className="pharagraph">You need not to pay or invest for introducing your tokens to the market. Just use the contract address of your token to list it on our Uniswap clone script and start trading with other popular tokens.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="424px" height="244px" src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/free-listing-of-tokens.png" alt="Free Listing of Tokens " />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Bug bounty reward system</h4>
                      <p className="pharagraph">Users can report the bugs to the organization that were spotted during the Swap, and receive recognition/compensation for them. (Especially for the security exploits and vulnerabilities).
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="499px" height="311px" src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/bug-bounty.png" alt="Bug bounty reward system" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Dark/Light Mode
                      </h4>
                      <p className="pharagraph">Dark mode reduces the luminance emitted by device screens and helps a pleasant viewing experience. Moreover, users can also use the traditional light mode to get a professional look.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="320px" height="320px" src="https://coinsclone.mo.cloudinary.net/images/uniswap-new/day-night-mode.png" alt="Dark/Light Mode" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon